<template>
  <div></div>
</template>
<script>
import { auth } from '../../sharedPlugin';
import * as base64 from 'base-64';

export default {
  methods: {
    ...auth.mapMethods(['logged']),
    async validate() {
      const accessToken = this.$route.params.token;
      await this.logged(accessToken);
      if (this.$route.params.route) {
        this.$router.push({
          path: `/${base64.decode(this.$route.params.route)}`
        });
      } else {
        this.$router.push(`/set-password/${accessToken}`);
      }
    }
  },
  mounted() {
    this.validate();
  }
};
</script>
